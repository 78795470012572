<template>
  <div class="container">
    <vs-button style="width: 120px;height: 35px" @click="()=>$router.push({name:'injuredParcel.index'})">
      <span style="margin-right: 4px;"><i class="fal fa-long-arrow-left"></i></span> ກັບຄືນໜ້າຫຼັກ
    </vs-button>
    <h4 class="title-header">ຮູບເຄື່ອງພັດສະດຸເສຍຫາຍ</h4>
    <div class="header">
      <div class="image-display" v-for="img in items.images" :key="img.id">
        <img style="cursor:pointer" @click="showFullImage(img)"
             :src="img.image_url"
             alt="">
      </div>
    </div>
    <h4 class="title-header">ລາຍລະອຽດພັດສະດຸ</h4>
    <div class="header">
      <div class="box-parcel">
        <div class="box-parcel-item barcode-parcel">
          <VueBarcode
              :height="50"
              :width="1.6"
              :text-margin="0"
              :font-size="18"
              :margin="2"
              :value=shipment?.shipment_number>
          </VueBarcode>
          <p style="margin-top: 8px;margin-bottom: 2px;color: #00a8ff">ສະຖານະພັດສະດຸ :</p>
          <div class="defaultStatusClass" :class="checkStatusClass(items.status)">
            {{ items.status_name }}
          </div>
        </div>
        <div class="box-parcel-item parcel-detail">
          <div style="display: flex;width: 100%">
            <h1 v-if="shipment.start_branch">
              {{ shipment.start_branch.name }}
            </h1>
            <div style="margin-left: 8px; margin-right: 8px">
              <i style="font-size: 22px !important;" class="fal fa-long-arrow-right"></i>
            </div>
            <h1 v-if="shipment.end_branch">
              {{ shipment.end_branch.name }}
            </h1>
          </div>
          <div style="margin-top: 12px;display: flex;flex-direction: column;align-items: flex-start">
            <h6 v-if="shipment.parcel">ປະເພດພັດສະດຸ :
              {{
                shipment.parcel.parcel_category_id === 11 ? shipment.parcel.name : shipment.parcel.parcel_category.name
              }}</h6>
            <h6>ເລກບິນ : {{ shipment.shipment_number }}</h6>
            <h6>ຄຳອະທິບາຍ : {{ items.description }}</h6>
            <h6>Remark : {{ items.remark }}</h6>
          </div>
        </div>
        <div class="box-parcel-item">
          <div>
            <vs-avatar size="70" badge badge-color="success">
              <img v-if="items.customer" :src="items.customer.profile_url" alt="">
            </vs-avatar>
          </div>
          <ul v-if="items.customer" style="margin-left: 0;">
            <li>ຊື່ລູກຄ້າ : <span>{{ items.customer.name }}</span></li>
            <li>ນາມສະກຸນ : <span>{{ items.customer.surname }}</span></li>
            <li>ເບີໂທລະສັບ : <span>{{ items.customer.tel }}</span></li>
          </ul>
        </div>
      </div>
    </div>

    <h4 class="title-header">ລາຍລະອຽດຄ່າຂົນສົ່ງ</h4>
    <div class="header">
      <div class="box-parcel">
        <div class="box-parcel-item barcode-parcel">
          <div>
            <vs-avatar size="70" badge badge-color="success">
              <img :src="require('@/assets/cash-on-delivery.png')" alt="">
            </vs-avatar>
            <h4 style="margin-top:  8px;">COD</h4>
            <h2>{{ $helpers.numberFormatter(shipment.total_price) }}</h2>
          </div>
        </div>
        <div class="box-parcel-item parcel-detail">
          <vs-avatar size="70" badge badge-color="success">
            <img :src="require('@/assets/money.png')" alt="">
          </vs-avatar>
          <h4 style="margin-top:  8px;">CDC</h4>
          <h2>{{
              $helpers.numberFormatter(shipment.shipment_pay_type === "origin_freight_fees_cod" ? 0 : shipment.total_freight)
            }}</h2>
        </div>
        <div class="box-parcel-item parcel-detail">
          <vs-avatar size="70" badge badge-color="success">
            <img :src="require('@/assets/money_1.png')" alt="">
          </vs-avatar>
          <h4 style="margin-top: 8px;">ຄ່າຂົນສົ່ງຕົ້ນທາງ</h4>
          <h2>{{
              $helpers.numberFormatter(shipment.shipment_pay_type === "origin_freight_fees_cod" ? shipment.total_freight : 0)
            }}</h2>
        </div>
      </div>
    </div>
    <h4 class="title-header">ການຕິດຕາມພັດສະດຸ</h4>
    <div class="header">
      <TimeLine :tracking="items.tracking"/>
    </div>
  </div>
</template>
<script>
import VueBarcode from "vue-barcode";
import TimeLine from "@/views/SuperAdmin/InjuredParcel/TimeLine.vue"

export default {
  components: {
    VueBarcode,
    TimeLine
  },
  data() {
    return {
      items: {},
      shipment: {},
    }
  },
  methods: {
    showFullImage(item) {

      window.open(item.image_url, '_blank');
    },
    checkStatusClass(status) {
      if (status === 3) {
        return "contacted-class";
      } else if (status === 1) {
        return "pending-class";
      } else if (status === 2) {
        return "accepted-class";
      } else if (status === 4) {
        return "approved-class";
      } else if (status === 5) {
        return "rejected-class";
      } else {
        return "resolved-class";
      }
    },
    async loadInjuredParcelDetail() {
      try {
        const config = {
          headers: {
            Locale: "lo"
          },
        };
        const id = this.$route.params.id;
        const res = await this.$http.get(`injured-parcel/${id}`, config);
        if (res.data.code === 200) {
          this.shipment = res.data.data.shipment;
          console.log(this.shipment, 444)
          this.items = res.data.data.data;
          console.log(this.items, 5555)
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  async created() {
    await this.loadInjuredParcelDetail();
  }
}


</script>

<style scoped lang="scss">

.defaultStatusClass {
  padding: 6px 12px;
  width: max-content;
  color: #ffffff;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 6px;
}

.contacted-class {
  background: #12CBC4;
}

.pending-class {
  background: rgba(249, 202, 36, 0.6);
}

.accepted-class {
  background: #0652DD;
}

.rejected-class {
  background: #e21312;
}

.approved-class {
  background: #A3CB38;
}

.resolved-class {
  background: #009432;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title-header {
    margin-top: 14px;
    margin-bottom: 4px;
  }

  .header {
    width: 100%;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 4px;
    border: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 1rem;

    .image-display {
      width: 190px;
      height: 190px;
      overflow: hidden;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }


    .box-parcel {
      width: 100%;
      display: grid;
      padding: 6px;
      grid-template-columns: 1fr 1fr 1fr; /* Three equal-width columns */
      grid-gap: 10px; /* Gap between grid items, adjust as needed */
    }

    .box-parcel-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      /* Styles for the grid items, adjust as needed */

      ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        li {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }

    .barcode-parcel {
      display: flex;
      flex-direction: column;

      .parcel-status {
        width: 200px;
        height: 30px;
        background: red;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        border-radius: 4px;
        margin-top: 4px;
      }
    }

    .parcel-detail {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 22px !important;
        margin: 0;
        padding: 0;
      }
    }
  }
}
</style>
