<script>

import {Validator} from "vee-validate";
import router from "@/router";

const dict = {
  custom: {
    installment: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    },
    amount: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    }
  },
};
Validator.localize("en", dict);
export default {
  data() {
    return {
      installArray: [
        {
          id: "first",
          name: "ງວດທີ່ໜື່ງ"
        },
        {
          id: "second",
          name: "ງວດທີ່ສອງ"
        },
        {
          id: "third",
          name: "ງວດທີ່ສາມ"
        },
      ],
      value: {
        installment: "",
        amount: ""
      },
      server_errors: {
        installment: "",
        amount: "",
      },
      isBtnLoading: false,

    }
  },
  methods: {
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.isBtnLoading = true;
      const id = this.$route.params.id;
      this.$http.put(`admin/update-installment/${id}`, this.value, {
        headers: {
          Locale: 'lo'
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.isBtnLoading = false;
          router.push({
            name: "injuredParcel.index"
          })
        }
      }).catch((error) => {
        this.isBtnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        if (error.response.status === 400) {
          const errorsMessage = error.response.data.message
          this.$notification.OpenNotification_Error('top-right', 'danger', 3000, errorsMessage);
        }
      });
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="update-installment">
      <vs-button style="width: 120px;height: 35px" @click="()=>$router.push({name:'injuredParcel.index'})">
        <span style="margin-right: 4px;"><i class="fal fa-long-arrow-left"></i></span> ກັບຄືນໜ້າຫຼັກ
      </vs-button>
      <div class="box-image"
           style="display: flex;flex-direction: column;justify-content: flex-start;align-items: center">
        <div class="image-route">
          <img :src="require('@/assets/finance.png')" alt="">
        </div>
        <h3>ອັບເດດຂໍ້ມູນງວດຈ່າຍ</h3>
        <div class="form-installment">
          <div class="field column">
            <div style="margin-top: 12px" class="control">
              <label style="margin-right: 10px; margin-top: 2px;" class="label">
                ເລືອກງວດທີ່ຈະຈ່າຍ <span class="text-danger">* {{ errors.first('installment') }}</span>
                <span class="text-danger">
              {{ server_errors.installment }}
            </span>
              </label>

              <div class="select">
                <select name="installment" class="input" v-validate="'required'" v-model="value.installment">
                  <option :value="item.id" v-for="item in installArray" :key="item.id">{{
                      item.name
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div style="margin-top: 12px" class="control">
              <label style="margin-right: 10px; margin-top: 2px;" class="label">
                ຈຳນວນເງິນ <span class="text-danger">* {{ errors.first('amount') }}</span>
              </label>
              <span class="text-danger">
              {{ server_errors.amount }}
            </span>
              <span class="text-danger" style="color: red">

            </span>
              <input
                  name="amount"
                  v-model="value.amount"
                  v-validate="'required'"
                  class="input"
                  type="text"
                  placeholder="ກະລຸນາປ້ອນກ່ອນ..."
              />
            </div>

            <div class="con-btns">
              <vs-button
                  @click="ValidateForm"
                  ref="button"
                  flat
                  class="form-btn-loading"
                  :loading="isBtnLoading"
              >ບັນທຶກຂໍ້ມູນ
              </vs-button
              >
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.update-installment {
  width: 100%;
  height: 100vh;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 14px;

  .box-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .image-route {
      width: 120px;
      height: 120px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.form-installment {
  width: 400px;
  height: 400px;
}
</style>
