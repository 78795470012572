<script>
export default {
  props: ['tracking']
}
</script>

<template>
  <div class="timeline-container" style="position: relative">
    <div class="timeline-block timeline-block-right" v-for="item in tracking" :key="item.id">
      <div class="marker"></div>
      <div class="timeline-content">
        <h3>{{ item.title_value}}</h3>
        <strong>{{ item.description_value }}</strong>
        <p>{{ item.operator_date }}</p>
      </div>
    </div>
    <div class="left-container">
      <div class="left-box">
        <div class="box-image">
          <img :src="require('@/assets/tracking.png')" alt="">
        </div>
        <h5 style="margin-top: 6px;">ຕິດຕາມສະຖານະພັດສະດຸ</h5>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.timeline-container {
  width: 90%;
  padding: 50px 0;
  margin: 25px auto;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 100%;
    background: #f6f6f6;
    background: linear-gradient(
            to bottom,
            transparent 0%,
            #f6f6f6 5%,
            #f6f6f6 95%,
            transparent 100%
    );
    z-index: 1;
  }

  .left-container {
    width: 100%;
    height: 100%;

    .left-box {
      width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

  }
}

.timeline-block {
  width: calc(50% + 8px);
  display: flex;
  justify-content: space-between;
  clear: both;

  &-right {
    float: right;
    text-align: left;
  }

  &-left {
    float: left;
    text-align: right;
    direction: rtl;
  }
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #abc9db;
  background: #abc9db;
  margin-top: 10px;
  z-index: 2;
}

.timeline-content {
  width: 95%;
  padding: 0 15px;

  h3 {
    margin-top: 5px;
    font-weight: 900;
    margin-bottom: 5px;
  }

  p {
    word-spacing: 1px;
    direction: ltr;
    hyphens: auto;
    hyphenate-limit-chars: 6 3 2;
    hyphenate-limit-lines: 2;
    hyphenate-limit-last: always;
    hyphenate-limit-zone: 8%;
  }
}

@media screen and (max-width: 768px) {
  .timeline-container::before {
    left: 8px;
    width: 2px;
  }
  .timeline-block {
    width: 100%;
    margin-bottom: 30px;
    text-align: left;
  }
  .timeline-block-right {
    float: none;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
  }
}

.box-image {
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

</style>
