<script>

import {Validator} from "vee-validate";
import router from "@/router";

const dict = {
  custom: {
    type: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    },
    contact_name: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    },
    contact_number: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    },
    description: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    },
    images: {
      required: "(ກະລຸນາປ້ອນກ່ອນ...)",
    }
  },
};
Validator.localize("en", dict);
export default {
  data() {
    return {
      isBtnLoading: false,
      items: {
        type: "",
        contact_number: "",
        contact_name: "",
        description: "",
        shipment_id: "",
      },
      image_list: [],
      preview_list: [],
      types: [],
      server_errors: {
        images: "",
        contact_number: "",
        contact_name: "",
        description: "",
        shipment_id: "",
      }
    }
  },
  methods: {
    previewMultiImage: function (event) {
      const input = event.target
      const file = input.files[0]
      const fileType = file.type
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg']
      if (!validImageTypes.includes(fileType)) {
        this.validateImage = '*ຮູບພາບຕ້ອງເປັນ jpeg,png,jpg';
        this.$notification.OpenNotification_CheckTypeImage('top-right', 'danger', 3000, this.validateImage);
      } else {
        let count = input.files.length
        let index = 0
        if (input.files) {
          while (count--) {
            const reader = new FileReader()
            reader.onload = e => {
              this.preview_list.push(e.target.result)
            }
            this.image_list.push(input.files[index])
            reader.readAsDataURL(input.files[index])
            index++
          }
        }
      }
    },
    showFullImage(imageUrl) {
      window.open(imageUrl, '_blank');
    },
    removeImageFormList(index) {
      this.preview_list.splice(index, 1)
    },
    async loadInjuredType() {
      // eslint-disable-next-line no-useless-catch
      try {
        const config = {
          headers: {
            Locale: "lo"
          },
        };
        const response = await this.$http.get(`injured-parcel-info?type=types`, config);
        if (response) {
          this.types = response.data.data;
        }
      } catch (error) {
        throw error;
      }
    },
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      const shipment_id = this.$route.params.id;
      this.isBtnLoading = true;
      let formData = new FormData();
      formData.append('type', this.items.type);
      formData.append('contact_number', this.items.contact_number);
      formData.append('contact_name', this.items.contact_name);
      formData.append('description', this.items.description);
      formData.append('shipment_id', shipment_id)
      this.image_list.forEach((image) => {
        formData.append(`images[]`, image);
      });
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      this.$http.post('injured-parcel', formData, config).then((res) => {
        if (res.data.code === 200) {
          router.push({
            name: "injuredParcel.index"
          })
          this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'success', 3000,);
        }
      }).catch((error) => {
        this.isBtnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors; // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
          this.$notification.OpenNotification_Error('top-right', 'danger', 3000, this.server_errors.shipment_id);
        }
      });
    },
  },
  created() {
    this.loadInjuredType();
  }
}
</script>

<template>
  <div class="container">
    <div class="form-system-route">
      <div style="width: 600px">
        <div class="field column">
          <div class="group-image-slip">
            <div
                class="group-show-image"
                v-for="(item, index) in preview_list"
                :key="index"
            >
              <img
                  @click="showFullImage(item.image_url)"
                  class="hover-image"
                  :src="item.url || item"
                  alt
              />
              <div @click="removeImageFormList(index)"
                   class="icon-delete-image"
              >
                <i class="fal fa-times-circle"></i>
              </div>
            </div>
            <div class="image-upload group-upload-image">
              <input
                  type="file"
                  @change="previewMultiImage($event)"
                  multiple
                  name="images"
                  v-validate="'required'"
              />
              <div class="image-box">
                <i class="fal fa-plus"></i>
                <p>ອັບໂຫລດຮູບພາບ</p>
              </div>
            </div>
            <span class="text-danger">* {{ errors.first("images") }}</span>
          </div>

          <div style="margin-top: 12px" class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ເລືອກປະເພດ <span class="text-danger">* {{ errors.first("type") }}</span>
              <span class="text-danger">
                {{ server_errors.type }}
            </span>
            </label>

            <div class="select">
              <select class="input" name="type" v-validate="'required'" v-model="items.type">
                <option :value="item.id" v-for="item in types" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div style="margin-top: 12px" class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຊື່ລູກຄ້າ <span class="text-danger">*  {{ errors.first("contact_name") }}</span>
            </label>
            <span class="text-danger" style="color: red">
            </span>
            <input
                v-model="items.contact_name"
                class="input"
                type="text"
                name="contact_name"
                v-validate="'required|max:191'"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            />
          </div>
          <div style="margin-top: 12px" class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ເບີໂທລູກຄ້າ <span class="text-danger">*  {{ errors.first("contact_number") }}</span>
            </label>
            <span class="text-danger" style="color: red">

            </span>
            <input
                v-model="items.contact_number"
                class="input"
                type="text"
                name="contact_number"
                v-validate="'required|max:191'"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            />
          </div>
          <div style="margin-top: 12px" class="control">
            <label style="margin-right: 10px; margin-top: 2px;" class="label">
              ຄຳອະທິບາຍ <span class="text-danger">*  {{ errors.first("description") }}</span>
            </label>
            <span class="text-danger" style="color: red">

            </span>
            <input
                v-model="items.description"
                class="input"
                type="text"
                name="description"
                v-validate="'required|max:191'"
                placeholder="ກະລຸນາປ້ອນກ່ອນ..."
            />
          </div>
          <div class="con-btns">
            <vs-button
                @click="ValidateForm"
                :loading="isBtnLoading"
                ref="button"
                flat
                class="form-btn-loading"
            >ບັນທຶກຂໍ້ມູນ
            </vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form-system-route {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .box-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .image-route {
      width: 120px;
      height: 120px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}

.group-image-slip {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .group-show-image {
    display: flex;
    margin: 0;
    position: relative;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border: 1px solid #0088ff;
      border-radius: 4px;
      position: relative;
      z-index: 1;
    }

    .hover-image {
      cursor: pointer;
    }

    .icon-delete-image {
      width: 20px;
      height: 20px;
      background-color: #ffffff;
      z-index: 3;
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 20px;

        &:hover {
          color: red;
        }
      }
    }
  }
}


//image upload
.image-upload {
  width: 100px;
  height: 100px;
  background-color: #eeeeee;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 0.2px dashed #b8b8b8;

  &:hover {
    border: 0.5px dashed #52a5ea;
    cursor: pointer;
    background-color: #f5f1f1;

  }


  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 3;

  }

  .image-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 2;
      cursor: pointer;
    }

    i {
      font-size: 2rem;
      color: #A3A9B9;
    }

    p {
      font-size: 12px;
    }
  }
}


</style>
